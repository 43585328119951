<template>
    <section class="fund">
        <Nav
            class="mt-5 mb-4"
            route-name="TechnologyTransfer"
            parent-page-name="Трансфер технологий"
            current-page-name="Эндаумент-фонд Университета ИТМО"
        />

        <div class="fund__main-img d-none d-xl-flex">
            <div class="fund__blurred-img mt-lg-auto ms-lg-auto d-flex align-items-lg-end">
                <div class="fund__title mt-auto">
                    <div>
                        <a
                            class="research-center__link"
                            target="_blank"
                            href="https://fund.itmo.family"
                        >
                            Перейти на сайт
                        </a>
                    </div>
                    {{ getFundPageInfo('title') }}
                </div>
                <img
                    src="../../assets/page-logo.svg"
                    class="d-block ms-auto mt-auto mb-3 d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <div class="fund__title fund__title--dark">
                <div>
                    <a
                        class="research-center__link"
                        target="_blank"
                        href="https://fund.itmo.family"
                    >
                        Перейти на сайт
                    </a>
                </div>
                {{ getFundPageInfo('title') }}
            </div>
        </div>

        <div class="fund__about-block d-flex flex-column flex-lg-row py-5">
            <div class="col-12 col-lg-6">
                <h3 class="fund__about-title">{{ getFundPageInfo('subtitle_1') }}</h3>
                <div class="fund__about-text mt-3">
                    {{ getFundPageInfo('subtext_1') }}
                </div>
            </div>

            <div class="col-12 col-lg-5 ms-lg-auto mt-4 mt-lg-0">
                <div class="text-card p-4 col-12 col-lg-10 ms-lg-auto">
                    <span class="text-card__definition">
                        <a href="https://fund.itmo.family" target="_blank">Эндаумент</a>
                    </span>
                    (англ. Endowment) – это целевой фонд, предназначенный для использования в
                    некоммерческих целях, как правило, для финансирования учреждений образования, медицины, культуры.
                    Фонды целевого капитала – это новые источники финансирования инновационных образовательных программ,
                    научных исследований.
                </div>
            </div>
        </div>

        <div class="fund__how-it-works-wrapper mt-4">
            <h3 class="how-it-works__title">{{ getFundPageInfo('subtitle_2') }}</h3>

            <div class="fund__how-it-works d-flex flex-column flex-lg-row mt-4">
                <div class="fund-card-wrapper me-4">
                    <div class="fund-card">
                        <div class="fund-card__img-wrapper d-flex justify-content-center">
                            <img class="fund-card__img d-block" src="../../assets/views/fund/img.svg" alt="">
                        </div>

                        <div class="fund-card__text p-4">{{ getFundCardText(1) }}</div>
                    </div>
                </div>

                <div class="fund-card-wrapper me-4">
                    <div class="fund-card">
                        <div class="fund-card__img-wrapper d-flex justify-content-center">
                            <img class="fund-card__img d-block" src="../../assets/views/fund/img2.svg" alt="">
                        </div>

                        <div class="fund-card__text p-4">
                            {{ getFundCardText(2) }}
                        </div>
                    </div>
                </div>

                <div class="fund-card-wrapper">
                    <div class="fund-card">
                        <div class="fund-card__img-wrapper d-flex justify-content-center">
                            <img class="fund-card__img d-block" src="../../assets/views/fund/img3.svg" alt="">
                        </div>

                        <div class="fund-card__text p-4">
                            {{ getFundCardText(3) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Nav from "@/components/Nav/Nav";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";
import {computed} from "vue";
import { useStore } from "vuex";

export default {
    name: "Fund",

    components: {
        Nav
    },

    setup() {
        const store = useStore()
        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('pages/getFundPage')
        const fundPage = computed(() => store.getters['pages/fundPage'])

        const getFundPageInfo = (key) => {
            if (fundPage.value) {
                return fundPage.value[key]
            }

            return ''
        }

        const getFundCardText = (id) => {
            if (fundPage.value) {
                return fundPage.value.cards.find(item => item.id === id).text;
            }
        }

        return {
            getFundPageInfo,
            getFundCardText
        }
    }
}
</script>
